(function () {
    "use strict";

    // @ngInject
    function PretrialersServiceCtor($state, AppStates, AppConfigService, AnalyticsService, UsersManager, EventsManager, AbTestService, EventService, Enums, $q) {
        this.$state = $state;
        this.AppStates = AppStates;
        this.AppConfigService = AppConfigService;
        this.AnalyticsService = AnalyticsService;
        this.UsersManager = UsersManager;
        this.AbTestService = AbTestService;
        this.EventService = EventService;
        this.EventsManager = EventsManager;
        this.Enums = Enums;
        this.$q = $q;
        this.mobileNavigation = false;
    }

    Services.PretrialersService = Class(function () {

        return {

            constructor: PretrialersServiceCtor,

            shouldShowCleanAtwsUI: function shouldShowCleanAtwsUI() {
                var user = this.UsersManager.getCurrUser();
                //should show clean UI only for atws who got the variation "clean". when removing the ab test watch not to
                // display to other user/pretrialers types
                // the ab test over here are coming with the user rabl
                return user.shouldStartTrial() &&
                    user.getExistingAbTestVariation(this.Enums.ABTests.atwsCleanUi) === 'clean';
            },

            getCompanyTypePluralPhrase: function getCompanyTypePluralPhrase(companyType) {
                if (this.Enums.ExtendedCreativeTypesPlural[companyType]) {
                    return this.Enums.ExtendedCreativeTypesPlural[companyType];
                } else {
                    return this.Enums.ExtendedCreativeTypesPlural['other'];
                }
            },

            gotoLastWorkspace: function gotoLastWorkspace() {
                var user = this.UsersManager.getCurrUser();
                var lastEvent = user.last_event_visited;

                if (lastEvent) {
                    var stateToGoTo = this.EventService.getEventDefaultState(lastEvent, false);
                    this.$state.go(stateToGoTo.name, angular.extend(stateToGoTo.params, {openEventsListOnLoad: this.EventService.currUserHasMultipleProjects()}), {
                        reload: true,
                        inherit: false
                    });
                } else if (user.get_user_workspaces_counts > 0) {
                    // user didn't clicked the email and logged in without visiting any project
                    var params = {};
                    params.sortColumn = "last_activity_date";
                    params.sortDirection = "descending";
                    this.EventsManager.getFilteredEvents(params).then(function (resp) {
                        if (resp.data.data.length > 0 && resp.data.data[0]._id !== undefined) {
                            var stateToGoTo = this.EventService.getEventDefaultState(resp.data.data[0]._id, false);
                            this.$state.go(stateToGoTo.name, angular.extend(stateToGoTo.params, {openEventsListOnLoad: this.EventService.currUserHasMultipleProjects()}), {
                                reload: true,
                                inherit: false
                            });
                        }
                    }.bind(this));
                }
            },
        };
    });
}());
